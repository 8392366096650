import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from "../../environments/environment";
import { Observable } from 'rxjs';

export interface ITotalPremium
{
  offerId: string;
  premiumAmount: number[];
}

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(
    private httpClient: HttpClient,
  ) { }


  updateRecotization(data: ITotalPremium): Observable<any> {
    return this.httpClient.post<any>(`${env.baseUrl}recotization`, data);
  }

  updateTransactionEntry(data: any): Observable<any> {
    return this.httpClient.post<any>(`${env.baseUrl}updTrans`, data);
  }
}
