import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment as env } from "../../environments/environment";

export interface ScheduleCallModel {
  fullname: string;
  phone_number: string;
  identification_number: string;
  date_preference: Date;
  hour_preference: Date;
}

@Injectable({
  providedIn: "root",
})
export class ScheduleCallService {
  constructor(private httpClient: HttpClient, private datePipe: DatePipe) {}

  public addScheduleCall(data: ScheduleCallModel): Observable<any> {
    return this.httpClient.post<any>(
      `${env.baseUrl}callscheduler/addscheduledcall`,
      {
        ...data,
        date_preference: data.date_preference?.toLocaleDateString(),
        hour_preference: this.datePipe.transform(
          data.hour_preference,
          "h:mm a"
        ),
      }
    );
  }
}
